import { graphql } from "gatsby"
import React from "react"
import Nav from "components/pages/Curriculum/components/Nav"
import Header from "components/pages/Curriculum/components/Header"
import Content from "components/pages/Curriculum/components/Content"
import DynamicZone from "components/Strapi/DynamicZone"
import SEO from "components/SEO"
import Layout from "../layouts/Curriculum"

interface IProps {
  path: string
  data: {
    strapiResourcePages: Strapi.ResourcePage
    site: {
      siteMetadata: { siteUrl: string }
    }
  }
}

const ResourcePageTemplate = (props: IProps) => {
  const category = props.data.strapiResourcePages.resource_category
  const page = props.data.strapiResourcePages

  const primary = category.Header?.bgGradient?.from

  const SEOProps = {
    title: page.SEO?.title || category.SEO?.title,
    titleTemplate: "%s | Night Zookeeper",
    image: page.SEO?.image?.url || category.SEO?.image?.url,
    url:
      page.SEO?.url ||
      `${props.data.site.siteMetadata.siteUrl}${props.path}` ||
      category.SEO?.url,
    canonical:
      page.SEO?.canonical ||
      `${props.data.site.siteMetadata.siteUrl}${props.path}` ||
      category.SEO?.canonical,
    description: page.SEO?.description || category.SEO?.description,
  }

  return (
    <Layout>
      <SEO {...SEOProps} titleTemplate="%s | Night Zookeeper" />
      <Nav
        items={[
          {
            slug: `${category.slug}`,
            title: `${category.title}`,
            to: `${category.customPath || "/resources"}/${category.slug}`,
          },
          ...category.resource_pages.map(p => ({
            slug: p.slug,
            title: p.title,
            to: `${category.customPath || "/resources"}/${category.slug}/${
              p.slug
            }`,
          })),
        ]}
        selected={page.slug}
        headerColor={primary || "#A5326C"}
      />
      <Header {...category.Header} {...(page.Header || {})} />
      <Content content={page.content} primary={primary} />
      <DynamicZone content={page.sections || []} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiResourcePages(strapiId: { eq: $id }) {
      slug
      title
      content
      Header {
        title
        subTitle
        button {
          id
          value
          theme
          targetUrl
        }
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 660, placeholder: BLURRED)
            }
          }
        }
        bgGradient {
          from
          to
          angleDegree
        }
      }
      sections {
        ...BookHero
        ...ZooHero
        ...ParentProductHero
        ...TeacherHero
        ...ShopHero
        ...PartnersGroup
        ...ExplanationHero
        ...TitleSeparator
        ...EngagingBanner
        ...BigIconsHero
        ...RatingsHero
        ...HowItWorks
        ...QuoteHero
        ...PlansHero
        ...PlanHero
        ...CarouselHero
        ...QuotesCarousel
        ...TeacherCTAHero
        ...FAQSection
        ...Partners
        ...Strapi_RichSection
        ...FixedBanner
        ...CTAStrip
        __typename
      }
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
      resource_category {
        id
        slug
        title
        customPath
        resource_pages(sort: { fields: slug, order: ASC }) {
          id
          title
          slug
        }
        Header {
          title
          subTitle
          button {
            id
            value
            theme
            targetUrl
          }
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(width: 660, placeholder: BLURRED)
              }
            }
          }
          bgGradient {
            from
            to
            angleDegree
          }
        }
        SEO {
          title
          description
          url
          noIndex
          image {
            url
          }
          canonical
        }
      }
    }
  }
`

export default ResourcePageTemplate
