import React from "react"
import RichTextRenderer from "components/UI/RichTextRenderer"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import getGatsbyImage from "utils/getGatsbyImage"

interface IProps {
  content: string
  primary?: string
}

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  > :first-child {
    width: 100%;
  }
`

const AdWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 0;
  height: 100%;
  width: calc((100vw - 1100px) / 2);
  max-width: 450px;
  padding: 20px 20px;
  .sticky {
    position: sticky;
    top: 64px;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
  img {
    border-radius: 12px;
  }
  @media (max-width: 1391px) {
    display: none;
  }
`

const Content = (props: IProps) => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "reading-writing-fun-9x16.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 300)
        }
      }
    }
  `)

  if (!props.content) return null
  return (
    <ContentWrapper>
      {props.content && (
        <RichTextRenderer primary={props.primary || "#962E63"}>
          {props.content}
        </RichTextRenderer>
      )}
      <AdWrapper>
        <div className="sticky">
          <Link to="/parents">
            <GatsbyImage
              image={getGatsbyImage(data.banner)}
              alt="Reading Writing Fun"
            />
          </Link>
        </div>
      </AdWrapper>
    </ContentWrapper>
  )
}

Content.defaultProps = {
  primary: "#962E63",
}
export default Content
