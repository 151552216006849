import { useEffect } from 'react'

const useEventListener = (event, callback, deps: any[] = []) => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener(event, callback)
    return () => {
      window.removeEventListener(event, callback)
    }
  }, deps)
}

export default useEventListener