import React from "react"
import TrialAdvert from "components/Adverts/TrialAdvert"
import MainLayout from "./Main"

interface IProps {
  children: any
}

const styles = {
  trialAdvert: {
    maxWidth: "1060px",
    margin: "0 auto",
  },
}

const CurriculumLayout = ({ children }: IProps) => {
  return (
    <>
      <MainLayout>
        {children}
        <div style={styles.trialAdvert}>
          <TrialAdvert />
        </div>
      </MainLayout>
    </>
  )
}

export default CurriculumLayout
