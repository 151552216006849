import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React from "react"
import styled, { css } from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  position: relative;
  ${(props: { bgGradient: Strapi.Gradient }) => css`
    background-image: linear-gradient(
      ${props.bgGradient?.angleDegree || 90}deg,
      ${props.bgGradient?.from || "#A5326C"},
      ${props.bgGradient?.to || "#772550"}
    );
  `}
  min-height: 250px;
  > :first-child {
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
  }
  color: #fff;
`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  min-height: 250px;
  width: 40%;
  align-items: flex-start;
  h1 {
    margin: 0;
  }
  > a:last-child {
    margin-top: auto;
    display: block;
    > :first-child {
      width: 100%;
    }
  }
  z-index: 2;
  @media (max-width: 1120px) {
    width: 100%;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    > a:last-child {
      margin-top: auto;
    }
  }
`

const BigTitle = styled.h1`
  letter-spacing: 2px;
  text-shadow: none;
  font-size: 2.5rem;
  font-family: "Rammetto One";
  @media (max-width: 400px) {
    font-size: 2.7rem;
  }
`

const SubTitle = styled.h2`
  letter-spacing: 2px;
  text-shadow: none;
  font-size: 1.5rem;
  @media (max-width: 400px) {
    font-size: 1.35rem;
  }
`

const MaskedImage = styled.div`
  position: relative;
  width: 60%;
  max-height: 250px;

  align-self: stretch;
  overflow: hidden;
  > :first-child {
    position: absolute;
    width: 100%;
    height: 102%;
    top: 0;
    left: 0;
    object-fit: cover;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  @media (max-width: 1120px) {
    display: none;
  }
`

interface IProps {
  title: string
  subTitle?: string
  button: Strapi.StrapiButton
  image: Strapi.UploadFile
  bgGradient: Strapi.Gradient
}

const Header = (props: IProps) => {
  return (
    <Wrapper bgGradient={props.bgGradient}>
      <div>
        <TitleSection>
          {props.title && <BigTitle>{props.title}</BigTitle>}
          {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
          {props.button && (
            <Link to={props.button.targetUrl}>
              <Button theme={props.button.theme || "confirm"} size="regular">
                {props.button.value}
              </Button>
            </Link>
          )}
        </TitleSection>
        <MaskedImage>
          {props.image && (
            <GatsbyImage
              image={getGatsbyImage(props.image.localFile)}
              alt={props.image.alternativeText}
            />
          )}
        </MaskedImage>
      </div>
    </Wrapper>
  )
}

Header.defaultProps = {
  subTitle: undefined,
}

export default Header
